import React, { useEffect, useState } from 'react';
import './EditAttractObj.css';
import FileBrowser from './FileBrowser';
import { IoCloseOutline } from 'react-icons/io5';
import updateAttractObjMutation from './updateAttractObjMutation';
import {
  Button,
  Divider,
  Flex,
  Heading,
  TextField,
} from '@aws-amplify/ui-react';
import { SketchPicker } from 'react-color';
import overlay from '../images/AttractOverlay.png';

export default function EditAttractObj(props) {
  const [attractObj, setAttractObj] = useState(props.attractObj);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [currentImagesToDelete, setCurrentImagesToDelete] = useState([]);

  const [title, setTitle] = useState(attractObj.title || '');
  const [subtitle, setSubtitle] = useState(attractObj.subtitle || '');
  const [image, setImage] = useState(attractObj.image || '');
  const [imagePreview, setImagePreview] = useState(attractObj.image.url || '');

  const [primary, setPrimary] = useState(attractObj.primaryColor || '');
  const [secondary, setSecondary] = useState(attractObj.secondaryColor || '');
  const [selectedColor, setSelectedColor] = useState('#fff');
  const [selectedColorBox, setSelectedColorBox] = useState('primaryColor');

  const [showSubmit, setShowSubmit] = useState(false);

  useEffect(() => {
    console.log('attractObj image', attractObj.image);
  }, []);

  useEffect(() => {
    checkIfCanSubmit();
  }, [title, image, primary, secondary]);

  //==========================================================================
  const handleCloseEditAttract = (e) => {
    if (hasSubmitted) return;
    if (
      e.target.classList.contains('edit-attractObj-form-container') ||
      e.target.classList.contains('edit-attractObj-close-icon')
    ) {
      const editAttractObjContainer = document.querySelector(
        '.edit-attractObj-form-container'
      );
      editAttractObjContainer.classList.add(
        'edit-attractObj-form-container-closed'
      );
      editAttractObjContainer.addEventListener(
        'animationend',
        () => {
          props.onCloseEditAttract();
        },
        { once: true }
      );
    }
  };

  //==========================================================================
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    checkIfCanSubmit();
  };

  const handleSubtitleChange = (e) => {
    setSubtitle(e.target.value);
    checkIfCanSubmit();
  };

  //==========================================================================
  const handleImageChange = (filesList) => {
    if (filesList.length < 1) {
      setImage('');
      setImagePreview('');
      checkIfCanSubmit();
      return;
    }
    console.log('filesList at image change', filesList);
    setImage(filesList[0] || null);
    const previewImage = filesList[0].url
      ? filesList[0].url
      : URL.createObjectURL(filesList[0].blob);
    setImagePreview(previewImage);
    checkIfCanSubmit();
  };
  const handleImageDelete = (imagesToDeleteList) => {
    if (imagesToDeleteList.length < 1) {
      setCurrentImagesToDelete([]);
      checkIfCanSubmit();
      return;
    }
    setImage('');
    setImagePreview('');
    setCurrentImagesToDelete(imagesToDeleteList);
    checkIfCanSubmit();
  };

  //==========================================================================

  //handle window resize to set title font to 100/1080 * the height of its parent container
  useEffect(() => {
    const handleResize = () => {
      const title = document.querySelector('.preview-attractObj-card-title');
      const subtitle = document.querySelector(
        '.preview-attractObj-card-subtitle'
      );
      const titleFontSize =
        ((100 / 1080) * title.parentElement.clientHeight).toString() + 'px';
      const subtitleFontSize =
        ((50 / 1080) * subtitle.parentElement.clientHeight).toString() + 'px';
      title.style.fontSize = titleFontSize;
      subtitle.style.fontSize = subtitleFontSize;
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //==========================================================================

  const checkIfCanSubmit = () => {
    if (
      title.length > 0 &&
      image.imageID &&
      primary.length > 0 &&
      secondary.length > 0
    ) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  };

  const handleFormSubmit = async (e, props) => {
    if (hasSubmitted) return;
    setHasSubmitted(true);
    let awaitEvent;
    try {
      awaitEvent = await updateAttractObjMutation(
        e,
        props,
        attractObj,
        title,
        subtitle,
        image,
        primary,
        secondary,
        currentImagesToDelete
      );
      const editAttractObjContainer = document.querySelector(
        '.edit-attractObj-form-container'
      );
      editAttractObjContainer.classList.add(
        'edit-attractObj-form-container-closed'
      );
      editAttractObjContainer.addEventListener(
        'animationend',
        () => {
          props.onCloseEditAttract();
        },
        { once: true }
      );
    } catch (error) {
      console.log('Error: could not create attract image : ', error);
      window.alert('Error: could not create attract image : ' + error);
      setHasSubmitted(false);
    }
  };

  return (
    <Flex
      className='edit-attractObj-form-container'
      onClick={handleCloseEditAttract}
    >
      <Flex className='edit-attractObj-card-container'>
        <Flex className='edit-attractObj-card'>
          <Button
            size='small'
            border={'none'}
            className='edit-attractObj-close-icon'
            onClick={(e) => {
              e.preventDefault();
              handleCloseEditAttract(e);
            }}
            style={{
              position: 'absolute',
              alignSelf: 'flex-end',
              cursor: 'pointer',
              margin: '0.5rem 0.5rem 0 0',
            }}
          >
            <IoCloseOutline size={'1.5rem'} pointerEvents={'none'} />
          </Button>
          <Heading level={5} style={{ textAlign: 'center' }}>
            Edit Attract Image
          </Heading>
          <form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit(e, props);
            }}
          >
            <Heading level={6}>Title</Heading>

            <TextField
              name='title'
              onChange={handleTitleChange}
              placeholder='Title'
              defaultValue={title}
            ></TextField>
            <Heading level={6} paddingTop={'1rem'}>
              Subtitle{' '}
            </Heading>
            <TextField
              name='subtitle'
              defaultValue={subtitle}
              onChange={handleSubtitleChange}
              placeholder='Optional'
            ></TextField>

            <FileBrowser
              heading={'Upload Single Image'}
              onFilesListChange={handleImageChange}
              onFilesListDelete={handleImageDelete}
              singleFile={true}
              viewIds={[attractObj.image.imageID]}
              onViewIds={(file) => setImage(file)}
            />
            <Divider margin={'1rem 0'}></Divider>
            <Heading level={5}>Background Box Colors</Heading>
            <Flex direction={'row'} padding={'1rem'}>
              <Flex direction={'column'} grow={1}>
                <Flex direction={'column'} grow={1}>
                  <Heading level={6}>Primary Color</Heading>

                  <div
                    id='primaryColor'
                    className='background-color-div'
                    style={{ background: primary }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedColorBox('primaryColor');
                      setSelectedColor(primary);
                      document
                        .getElementById('secondaryColor')
                        .classList.remove('selected-box');
                      e.target.classList.add('selected-box');
                      checkIfCanSubmit();
                    }}
                  ></div>
                </Flex>
                <Flex direction={'column'} grow={1}>
                  <Heading level={6}>Secondary Color</Heading>

                  <div
                    id='secondaryColor'
                    className='background-color-div'
                    style={{ background: secondary }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedColorBox('secondaryColor');
                      setSelectedColor(secondary);
                      document
                        .getElementById('primaryColor')
                        .classList.remove('selected-box');
                      e.target.classList.add('selected-box');
                      checkIfCanSubmit();
                    }}
                  ></div>
                </Flex>
              </Flex>
              <SketchPicker
                className='sketch-picker-container'
                disableAlpha={true}
                presetColors={[
                  '#81A9C3',
                  '#845577',
                  '#514269',
                  '#CDADB8',
                  '#FFD181',
                  '#A34F61',
                  '#C5D8E4',
                ]}
                color={selectedColor}
                onChangeComplete={(color) => {
                  setSelectedColor(color.hex);
                  console.log('selectedColorBox', selectedColorBox);
                  if (selectedColorBox === 'primaryColor') {
                    setPrimary(color.hex);
                  }
                  if (selectedColorBox === 'secondaryColor') {
                    setSecondary(color.hex);
                  }
                }}
              />
            </Flex>

            <Divider
              style={{
                margin: '1rem 0',
              }}
            />

            <Heading width={'100%'} level={5}>
              Preview
            </Heading>
            <div className='preview-attractObj-card'>
              <div className='preview-example-container'>
                <div
                  className='preview-attractObj-card-secondary'
                  style={{
                    background: secondary,
                  }}
                ></div>
                <div
                  className='preview-attractObj-card-primary'
                  style={{
                    background: primary,
                  }}
                ></div>
                <img
                  className='preview-attractObj-card-img'
                  src={imagePreview}
                  alt=''
                />
                <p className='preview-attractObj-card-title'>{title}</p>
                <p className='preview-attractObj-card-subtitle'>{subtitle}</p>
              </div>
              <img
                className='preview-attractObj-card-overlay'
                src={overlay}
              ></img>
            </div>
            <Divider
              style={{
                margin: '1rem 0',
              }}
            />
            <Flex
              justifyContent={'space-between'}
              style={{
                margin: '1rem 0',
              }}
            >
              <Button
                className='edit-attractObj-close-icon'
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseEditAttract(e);
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                isDisabled={!showSubmit}
                isLoading={hasSubmitted}
              >
                Submit
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
}
