import { useState, useEffect } from 'react';
import { API, Hub } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';
import { CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';

export function useAttractSubscriptions() {
  const [attractData, setAttractData] = useState([]);

  useEffect(() => {
    Hub.listen('api', (data) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
      }
    });

    const createAttractSub = API.graphql({
      query: subscriptions.onCreateAttractObj,
    }).subscribe({
      next: (attractData) => {
        const newAttract = attractData.value.data.onCreateAttractObj;
        setAttractData((prev) => [...prev, newAttract]);
        console.log('newAttract from sub', newAttract);
      },
    });

    const updateAttractSub = API.graphql({
      query: subscriptions.onUpdateAttractObj,
    }).subscribe({
      next: (attractData) => {
        const updatedAttract = attractData.value.data.onUpdateAttractObj;
        setAttractData((prev) => {
          const oldAttractEntries = prev.filter(
            (entry) => entry.id !== updatedAttract.id
          );
          const newAttractEntries = [...oldAttractEntries, updatedAttract];
          return newAttractEntries;
        });
      },
    });

    const deleteAttractSub = API.graphql({
      query: subscriptions.onDeleteAttractObj,
    }).subscribe({
      next: (attractData) => {
        const deletedAttract = attractData.value.data.onDeleteAttractObj;
        setAttractData((prev) =>
          prev.filter((entry) => entry.id !== deletedAttract.id)
        );
        console.log('deletedAttract from sub', deletedAttract);
      },
    });
    //====================================================================================================

    //====================================================================================================
    return () => {
      createAttractSub.unsubscribe();
      updateAttractSub.unsubscribe();
      deleteAttractSub.unsubscribe();
    };
  }, []);

  return { attractData, setAttractData };
}
