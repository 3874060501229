import {
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  SwitchField,
} from '@aws-amplify/ui-react';
import missingImage from '../images/ImagePlaceHolder.png';
import './ShowAttract.css';
import {
  IoAddOutline,
  IoPencilOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { handleKioskReady } from './AllEntries/handleKioskReady';
import { getImages, deleteImages } from './SaveImage';
import { getAttractObj } from '../graphql/queries';
import { deleteAttractObj } from '../graphql/mutations';
import { API } from 'aws-amplify';

export default function ShowAttract(props) {
  const [attractObjs, setAttractObjs] = useState(props.data);

  useEffect(() => {
    async function getImagesFromS3(attractObj) {
      if (attractObj.image.url) return attractObj;
      const imageArray = await getImages([attractObj.image]);
      return { ...attractObj, image: imageArray[0] };
    }
    const fetchAllImages = async () => {
      const imageArray = await Promise.all(
        props.data.map((attractObj) => getImagesFromS3(attractObj))
      );
      setAttractObjs(imageArray);
    };
    fetchAllImages();
  }, [props.data]);

  const handleAddAttract = async () => {
    props.onAddAttract();
    console.log('add attractObj');
    console.log('props.data', props.data);
  };
  const handleEditAttract = (attractObj) => {
    console.log('edit attractObj', attractObj);
    props.onEditAttract(attractObj);
  };

  const handleDeleteAttract = async (attractObj) => {
    try {
      const attractResult = await API.graphql({
        query: getAttractObj,
        variables: { id: attractObj.id },
      });
      if (!attractResult.data || !attractResult.data.getAttractObj) {
        console.log('No AttractObj found with id', attractObj.id);
        return;
      }

      const imageID = attractResult.data.getAttractObj.image;
      try {
        await deleteImages([imageID]);
      } catch (error) {
        console.log('Error deleting image', error);
        return;
      }

      const deleteResult = await API.graphql({
        query: deleteAttractObj,
        variables: { input: { id: attractObj.id } },
      });

      if (!deleteResult.data || !deleteResult.data.deleteAttractObj) {
        console.log('Error deleting AttractObj', deleteResult);
        return;
      }
    } catch (error) {
      console.log('Error deleting AttractObj', error);
    }
  };

  return (
    <div className='show-Attract'>
      <Flex
        justifyContent={'center'}
        width={'100%'}
        direction={'column'}
        alignItems={'center'}
        style={{
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          zIndex: '1',
        }}
      >
        <Heading className='attract-loop-nav' color={'#047d95'} level={5}>
          Click the Pencil to Edit an Attract Image
        </Heading>
        <Divider width={'100%'} />
      </Flex>

      <div className='show-attractObjs-list'>
        <div
          className='show-attractObjs-list-card add-attractObj'
          onClick={() => handleAddAttract()}
        >
          <IoAddOutline
            size={'1.5rem'}
            style={{
              pointerEvents: 'none',
            }}
          />
          <Text className='add-attractObj-text'>Add Attract</Text>
        </div>
        {attractObjs.map(
          (attractObj, index) =>
            attractObj && (
              <div className='show-attractObjs-list-card' key={attractObj.id}>
                <IoCloseCircleOutline
                  className='delete-attractObj'
                  onClick={() => handleDeleteAttract(attractObj)}
                />
                <div className='attract-image'>
                  <img
                    className='show-attractObjs-list-card-img'
                    src={attractObj?.image?.thumbnailUrl || missingImage}
                    alt={attractObj.title || 'attract image'}
                  />
                </div>
                <div className='attract-image-info'>
                  <Text>{attractObj.title || ''}</Text>
                  <IoPencilOutline
                    className='edit-attractObj-pencil'
                    onClick={() => handleEditAttract(attractObj)}
                    size={'1.5rem'}
                  />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
