/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://oe7rd25vdjestg6nskqixpnbfi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-afz7azgyyzgdfgskxufe7uy2v4",
    "aws_cognito_identity_pool_id": "us-east-2:8c9940d5-2b75-4a03-b76c-db71592f6bdc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ruJITSwxa",
    "aws_user_pools_web_client_id": "8s2t4gm7pdenoq5qqh28scfn2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "siouxcityupload-storage-ced185033-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
