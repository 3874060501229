import { API } from 'aws-amplify';
import { updateAttractObj } from '../graphql/mutations';
import { saveImages, deleteImages } from './SaveImage';

const updateAttractObjMutation = async (
  e,
  props,
  attractObj,
  title,
  subtitle,
  image,
  primaryColor,
  secondaryColor,
  currentImagesToDelete
) => {
  try {
    let uploadingImages;
    if (image) {
      try {
        uploadingImages = await saveImages(
          [image],
          'Images',
          props.currentUser
        );
      } catch (error) {
        console.log('Error uploading images:', error);
        window.alert(
          'There was an error uploading these images, please try deleting and reuploading.'
        );
        throw error; // Throw the error to stop further execution
      }
    }
    if (currentImagesToDelete.length > 0) {
      try {
        console.log('currentImagesToDelete:', currentImagesToDelete);
        await deleteImages(currentImagesToDelete);
      } catch (error) {
        console.log('Error deleting images:', error);
        window.alert(
          'There was an error deleting these images, please try again.'
        );
        throw error; // Throw the error to stop further execution
      }
    }
    const response = await API.graphql({
      query: updateAttractObj,
      variables: {
        input: {
          id: attractObj.id,
          title: title,
          subtitle: subtitle,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          image: uploadingImages ? uploadingImages[0] : null,
        },
      },
    });
    return response.data;
  } catch (err) {
    console.log('error updating Attract image : ', err);
    window.alert('Error on update. Please try again.');
    return Promise.reject(err);
  }
};

export default updateAttractObjMutation;
