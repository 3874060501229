import { API } from 'aws-amplify';
import { createAttractObj } from '../graphql/mutations';
import { saveImages } from './SaveImage';

const createAttractMutation = async (
  e,
  props,
  title,
  subtitle,
  image,
  primaryColor,
  secondaryColor
) => {
  try {
    let uploadingImages;
    console.log('image: ', image);
    if (image) {
      try {
        uploadingImages = await saveImages(
          [image],
          'Images',
          props.currentUser
        );
      } catch (err) {
        console.log('error uploading these images : ', err);
        window.alert(
          'There was an error uploading these images, please try deleting and reuploading. '
        );
        throw err;
      }
    }
    try {
      await API.graphql({
        query: createAttractObj,
        variables: {
          input: {
            title: title,
            subtitle: subtitle || '',
            image: uploadingImages ? uploadingImages[0] : null,
            primaryColor: primaryColor || '',
            secondaryColor: secondaryColor || '',
            kioskReady: true,
          },
        },
      });
    } catch (err) {
      console.log('error creating AttractObj 1: ', err);
      throw err;
    }
  } catch (err) {
    console.log('error creating AttractObj 2: ', err);
    throw err;
    return Promise.reject(err);
  }
};

export default createAttractMutation;
